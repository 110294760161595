import { startScan, prepare, stopScan } from '@/plugins/barcodeScanner'
import { infoDevice } from '@/plugins/system/device'
import Button from 'primevue/button'
import Card from 'primevue/card'

import {
  ref,
  onMounted
} from 'vue'

export default {
  components: {
    Button,
    Card
  },
  setup() {
    let result = ref('My result in here yahh')

    const onBarcodeScanner = async () => {
      await prepare()
      result = await startScan()
      await stopScan()
    }

    onMounted(async () => {
      console.log(await infoDevice())
    })

    return {
      result,
      onBarcodeScanner
    }
  }
}
